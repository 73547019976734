var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getDataList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    editable: _vm.editable,
                    comboItems: _vm.useFlagItems,
                    label: "LBLUSEFLAG",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "useFlag",
                    type: "search",
                  },
                  model: {
                    value: _vm.searchParam.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "useFlag", $$v)
                    },
                    expression: "searchParam.useFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "검사항목 목록",
                  tableId: "table",
                  columns: _vm.grid.columns,
                  gridHeight: _vm.grid.height,
                  data: _vm.grid.data,
                  columnSetting: false,
                },
                on: { rowClick: _vm.rowClick },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "LBLSEARCH", icon: "search" },
                          on: { btnClicked: _vm.getDataList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBLDETAIL" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "LBLREG", icon: "add" },
                                  on: { btnClicked: _vm.addData },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.saveable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.saveType,
                                    label: "LBLSAVE",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.updateMode,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-6" }),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              required: true,
                              label: "검사항목명",
                              name: "envWaterMstInspectItemName",
                            },
                            model: {
                              value: _vm.data.envWaterMstInspectItemName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.data,
                                  "envWaterMstInspectItemName",
                                  $$v
                                )
                              },
                              expression: "data.envWaterMstInspectItemName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              required: true,
                              label: "법적기준",
                              type: "number",
                              name: "legalStandard",
                            },
                            model: {
                              value: _vm.data.legalStandard,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "legalStandard", $$v)
                              },
                              expression: "data.legalStandard",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              required: true,
                              label: "내부기준",
                              type: "number",
                              name: "innerStandard",
                            },
                            model: {
                              value: _vm.data.innerStandard,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "innerStandard", $$v)
                              },
                              expression: "data.innerStandard",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              isFlag: true,
                              label: "LBLUSEFLAG",
                              name: "useFlag",
                            },
                            model: {
                              value: _vm.data.useFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "useFlag", $$v)
                              },
                              expression: "data.useFlag",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }